<template>
    <div>
        <Row  :gutter="8" class="p-b-5">
            <i-col span="6">
                <Select size="small" placeholder="线路" clearable>
                    <Option value="1">青岛地铁2号线</Option>
                    <Option value="2">青岛地铁3号线</Option>
                    <Option value="3">青岛地铁4号线</Option>
                    <Option value="4">青岛地铁13号线</Option>
                </Select>
            </i-col>
            <i-col span="6">
                <Select size="small" placeholder="站点" clearable>
                    <Option value="1">南岭站</Option>
                    <Option value="2">兴国路站</Option>
                    <Option value="3">永年路站</Option>
                    <Option value="4">沧安路站</Option>
                    <Option value="5">青岛北站</Option>
                </Select>
            </i-col>
            <i-col span="6">
                <Input type="text" size="small" placeholder="关键词（资源编号、站点编号、站点名称）" />
            </i-col>
            <i-col span="6">
                <Button icon="ios-search" size="small" type="primary">搜索</Button>
            </i-col>
        </Row>

        <Divider dashed style="margin: 5px 0;"/>

        <Row class="p-b-5">
          <i-col span="12">
            <Button size="small" type="warning">批量暂停</Button>
            <Button size="small" class="m-l-5" type="success">批量播放</Button>
          </i-col>
          <i-col span="12" class="text-right">
            显示全部设备：
            <i-switch size="large" true-color="#13ce66">
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
            </i-switch>
          </i-col>
        </Row>

        <Table stripe size="small" :data="list" :columns="dataColumns"></Table>
        <div class="paging_style">
          <Page size="small" :total="50" :page-size="10" show-total show-elevator :current="1"></Page>
        </div>
    </div>
</template>

<script>
import { formatEnable } from '@/utils/tagStatus'

export default {
  data () {
    return ({
      dataColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        { title: '线路', key: 'asset' },
        { title: '站点', key: 'station' },
        { title: '资源编号', key: 'code' },
        {
          title: '投放状态',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.enable)
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {

                  }
                }
              }, '查看位置'),
              h('a', {
                on: {
                  click: () => {
                    this.$store.commit('set_electronic_update', new Date())
                  }
                }
              }, '投放计划')
            ]
            )
          }
        }
      ],
      list: []
    })
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.list = [
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: true },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: false },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: true },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: false },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: false },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: true },
        { asset: '青岛地铁2号线', station: '南岭站', code: '1-DGZ-AL1202(-1)', enable: true }
      ]
    }
  }
}
</script>
